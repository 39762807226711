
@import "global";

.app-content{
  flex: 1 0 auto;
}
.turkis{
  color: $turquoise !important;
}
.dark-purple{
 color: $dark-purple;
}
.light-blue{
 color: $light-blue;
}

.italic{
 font-style: italic;
}
.float-right{
 float: right;
}
.float-left{
 float: left;
}
.space-after{
  margin-bottom:  25px !important;
}
.big-space-after{
 margin-bottom:  40px !important;
}
.space-before{
  margin-top: 25px !important;
}
.big-space-before{
  padding-top: 40px !important;
}
a{
  color: $turquoise;
}
a:hover{
  color: #418f8b;
  text-decoration: none;
}
mark{
  padding: 0;
}
.page-margin{
  max-width: 1080px;
  width: 80%;
  margin: auto;
  padding: 0;
}
h1, h2, h3 {
  font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif;
}
h2 {
    display: block;
    font-size: 1.4em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}
h1 {
  font-weight: bolder;
  font-size: 2.5rem;
}
section.background {
  padding: 70px 0;
  width: 100%;
}
section.blue{
  background-color: #c4e0db;
}
section.water{
  background-color: $water-blue;
}
section h1{
  text-align: center;
  margin-bottom: 1rem;
}

.container img {
  margin-bottom: 40px;
}
figure > img, a > img{
  margin-bottom: 0px !important;
}

.image-caption{
  text-align: center;
  font-weight: bold;
  font-size: large;
}
.klima-footer{
  padding: 30px;
  text-align: center;
  flex-shrink: 0;
  width: 100%;
  background-color: #1a0c41;
  color: white;
}
.copyright{
  font-size: 14px;
  margin: 30px auto 10px;
}
.references{
  color: white;
}
.social-icon, .social-icon:active{
  width: 18px;
  height: 18px;
  margin: 10px;
  color: white;
}
.filled-icon{
  stroke-width: 1px;
  fill: white;
}
.no-fill-icon
{
  stroke-width: 3px;
}
.scale-for-ie{
  width: 100%;
}

.search-term{
  font-weight: bolder;
  text-decoration-line: underline;
}

@include media-breakpoint-down(md) {
  
  .hidden-xs-md{
    display: none;
  }
}
