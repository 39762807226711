@import "global";

#back2Top{
  display: none;
  position: fixed;
  z-index: 99999;
  right: 0;
  bottom: 125px;
  border: none;
  outline: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -webkit-border-top-left-radius: 5px;
  -moz-border-radius-topleft: 5px;
  border-top-left-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  background: rgba(0, 0, 0, 0.4);
  padding: 5px 10px 10px 12px;
  cursor: pointer;
}