@import "global";

.all-jumbotron{
    color: white !important;
    padding: 0;
    display: flex; /*vertically center*/
    align-items: center; /*vertically center*/
    min-height:10%;  /*Fallback for browsers do NOT support vh unit*/
    min-height: 10vh; /*These two lines are counted as one :-)   */ 
    border-radius: 0px;  
  }
  .klima-jumbotron{
    background-size: cover;
    background-position-x: center;
    height: 340px;
  }
  .cat-jumbotron{
    height: 370px;
    background-color:$light-blue;
  }
  .klima-jumbotron-title {
    line-height: normal;
  }
  .flex-container-landingpage-title{
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
  }
  .landingpage-title-icon{
    flex: 0 0 250px;//grow shrink basis
  }
  .landingpage-title-text{
    flex: 0 1 700px;
  }
.klima-jumbotron-title h1{
      font-size: 60px;
      font-weight: bolder;
      text-shadow: 1px 1px 5px #666666;
      text-align: center;
      text-align: center;
    }
.cat-jumbotron h1{
    margin: auto;
    text-align: center;
  }
.klima-jumbotron-title h2{
    margin: 10px 0;
    font-size: 55px;
    text-shadow: 1px 1px 10px #444444;
  }
  .klima-jumbotron-title p{
    font-size: 30px;
    font-weight: 500;
    text-shadow: 1px 1px 10px #222222;
  }
  .cat-jumbotron-title{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cat-jumbotron-title-icon{
    width: 200px;
  }
.wordWrapHeader{//this fixes word wrap at the right places (more or less)
  overflow-wrap: break-word;
  padding-left: 14vw;
  padding-right: 14vw;
}
.disclaimer{
  font-style: italic;
  position: absolute;
  top: 420px;
}
.disclaimer-mobile{
  font-style: italic;
  text-align: center;
  display: none;
}

@include media-breakpoint-down(md) { //Medium devices (tablets, less than 992px)
  .landingpage-title{
    text-align: center !important;
  }
  .landingpage-title-icon{
    display: none;
  }
  .klima-jumbotron-title h2{
    font-size: 40px;
    font-weight: bolder;
  }
  .klima-jumbotron-title p{
    font-size: 25px;
  }
  .disclaimer{
    display: none;
  }
  .disclaimer-mobile{
    display: block;
  }

} 

@include media-breakpoint-down(sm) {// Small devices (landscape phones, less than 768px)
  .all-jumbotron{
    height: 200px;
    margin-bottom: 10px;
  }
  .klima-jumbotron-title {
    padding: 0px;
  }
  .cat-jumbotron-title-icon{
    width: 100px;
  }
}
/* iphone X , XS, 11 Pro */
@media only screen
and (min-device-width: 375px) 
and (max-device-height: 812px) 
and (-webkit-device-pixel-ratio: 3)
and (orientation : landscape)  { 
  .all-jumbotron{
    height: 200px;
    margin-bottom: 10px;
  }
  .klima-jumbotron-title {
    padding: 0px;
  }
  .cat-jumbotron-title-icon{
    width: 100px;
  }
}
  

  @include media-breakpoint-down(xs) 
 { // Extra small devices (portrait phones, less than 576px)
    .klima-jumbotron{
      height: 300px;
    }
    .cat-jumbotron{
      height: 250px;
    }
    .klima-jumbotron-title h2{
      font-size: 37px;
    }
    .klima-jumbotron-title p{
      font-size: 21px;
    }
  }

  @media only screen //older devices with smaller landscape witdh than newer devices height
  and (max-device-width: 576px) 
  and (orientation : landscape){
    .all-jumbotron{
      height: 200px;
      margin-bottom: 10px;
    }
  }