@import "global";

@include media-breakpoint-down(md) { //Medium devices (tablets, less than 992px)
  
  
  body {  
    padding-top: 80px;
  }

  .page-margin{
    width: 85%;
  }
}
@include media-breakpoint-down(sm) {// Small devices (landscape phones, less than 767.98px)
 
  .page-content{
    padding-left: 30px;
    padding-right: 30px;
  }
  body {  
    padding-top: 70px;
  }
  .page-margin{
    width: 100%;
    margin: 0 !important;
    padding: 10px;
  }
 
}
@include media-breakpoint-down(xs) { // Extra small devices (portrait phones, less than 576px)
  
  body {  
    padding-top: 60px;
  }
  .page-margin{
    width: 100%;
    margin: 0px !important;
    padding: 10px;
  }
}
