@import "global";

.navbar-wrapper{
  position: fixed;
  z-index: 99;
  top: 0px;
  width: 100%;
  height: 100px;
  background-color: white;
  box-shadow: 0px 1px 3px #888888;
}
.klima-navbar{
  background-color: #FFFFFF;
  color: '#FF0000';

  height: 100%;

  padding-top: 0em;
  padding-bottom: 0em;
  
  font-weight: bold;
  text-align: center;
} 
.nav-item, .dropdown-item, .dropdown-toggle {
  font-size: 15px;
  color: #418f8b !important;
}
Nav a {
  display: block;
  padding: .5rem;
}
.dropdown-item .active {
  background-color: none;
}
a#navbarDropdown.nav-link.dropdown-toggle:active,
a#navbarDropdown.nav-link.dropdown-toggle:focus {
   outline: 0;
}
.search-icon{
  margin: 5px;
}
.search-field-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

@include media-breakpoint-down(lg) { //Large devices (tablets and screens, less than 1200px)
  .navbar-collapse{
    background-color: white;
    padding: 0 20px;
    position: relative;
    width: 80%;
    border-top: 3px solid $turquoise;
  }
  .klima-brand{
    height: 100%;
    display: flex;
    align-items: center;      
  }
  .klima-navbar{
      margin-top: 0em;
  }
  .navbar-brand img{
    height: 70px;
  }
}

@include media-breakpoint-down(sm) {// Small devices (landscape phones, less than 767.98px)
  .navbar-wrapper{
    position: relative;
    height: 73px;
  }
  .klima-navbar{
    padding: .5rem 1rem;
  }
  .navbar-collapse{
    top: .5rem;
  }
  body {  
    top: 0px;
    padding-top: 0px;
  }
  .navbar-brand img{
    height: 60px;
  }
}

@media only screen
  and (min-device-width: 375px) 
  and (max-device-height: 812px) 
  and (-webkit-device-pixel-ratio: 3)
  and (orientation : landscape)  { 
  .navbar-wrapper{
    position: relative;
    height: 73px;
  }
  .klima-navbar{
    padding: .5rem 1rem;
  }
  .navbar-collapse{
    top: .5rem;
  }
  body {  
    top: 0px;
    padding-top: 0px;
  }
  .navbar-brand img{
    height: 60px;
  }
}

@include media-breakpoint-down(xs) { // Extra small devices (portrait phones, less than 576px)
  .navbar-brand img{
    height: 100%;
  }
  .navbar-wrapper{
    height: 115px;
  }
  .klima-brand{
    height:65%;
  }
}