
@import "global";

.contact-container{
  padding-bottom: 100px;
}
.form-container{
  background-color: $light-blue;
  padding: 65px 40px;
}
.retry-button, .retry-button:hover{
  color: #ffffff!important;
  font-size: 20px;
  border: none;
  border-radius: 0px;
  background-color: #1a0c41;
}
.form-button, .form-button:hover{
  color: #ffffff!important;
  font-size: 20px;
  border: none;
  border-radius: 0px;
  background-color: #1a0c41;
  float: right;
}
.form-button{
  width: 80px;
  transition: 1s;
}
.form-button:hover{
  padding-right: 0px;
  width: 100px;
}
.form-button>.right-arrow{
  width: 0px;
  margin-left: 0px;
  transition: 0.7s;
  margin-bottom: 5px;
}
.form-button:hover>.right-arrow{
  width: 20px;
  margin-left: 10px;
  margin-bottom: 5px;
}
.response-text{
  font-size: 1.1em;  
}
.form-group input, .form-group textarea, .form-group select{
  border-radius: 0px;
  background-color: #eee;
  color: #000000;
}
.number-input{
  display: none;
}
.age-input{
  display: none;
}

@include media-breakpoint-down(sm) {
  .form-container{
    padding: 35px 25px 55px 25px;
  }
}
