html, #root{
  height: 100%;
}
body {  
 /* position: relative; */
 /* top: 90px; */
  padding-top: 90px;
  /* font-family: 'Open Sans',Helvetica,Arial,Lucida,sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-height: calc(100vh - 52px); */
  height: 100%;
}
#root{
  display: flex;
  flex-direction: column;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}