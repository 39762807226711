@import "global";

.button-container a{
    text-decoration: none;
    cursor: pointer;
  }
  .top-button-container{
    margin-bottom: 120px !important;
  }
  .sub-button-container{
    margin-bottom: 30px;
  }
  .single-button-container-top{
    padding: 20px;
  }
  .category-button{
    position: relative;
    width: 100%;
    cursor: pointer;
    border-radius: 3px;
  }
  .category-buttons-3{
    padding: 36% 0px;

    .category-icon{
      width: 7rem;
      margin: auto;
    }
  }
  .category-buttons-4{
    padding: 50% 0px;

    .category-icon{
      width: 6rem;
      margin: auto;
    }
  }
  .category-button-content{
    position:absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .category-button:active {
    box-shadow: 0px 0 0 white;
  }
  .category-button h2{
    font-size: 1.3em;
    text-align: center;
  }
  .top-category-button{
    background-color:$light-blue;
    box-shadow: 0px 1px 2px #666666;
  }
  .sub-category-button{
    background-color:#1A0C41;
    box-shadow: 0px 1px 2px $light-blue;
  }

  html {
    font-size: 14px;
  }
  .top-button-row{
    margin-right: -20px;
    margin-left: -20px;
  }

  @include media-breakpoint-down(md) { //Medium devices (tablets, less than 892px)
    .single-button-container-cat{
        padding: 15px;
      }

      .category-buttons-4, .category-buttons-3{
        padding: 27.5% 0px;

        .category-icon{
          width: 6rem;
          margin: auto;
        }
      }
  }

  @include media-breakpoint-down(sm) {// Small devices (landscape phones, less than 768px)
    .row{
      margin-right: 0;
      margin-left: 0;
    }
     
  }

  @include media-breakpoint-down(xs) { // Extra small devices (portrait phones, less than 576px)

    .top-button-container{
      margin-bottom: 60px !important;
    }
    .sub-button-container{
      margin-bottom: 30px !important;
    }
    .single-button-container-top, .single-button-container-cat{
      padding: 10px;
    }

    .category-buttons-4, .category-buttons-3{
      padding: 35% 0px;

      .category-icon{
        width: 4rem;
        margin: auto;
      }
    }
    
    .category-button-content h2{
      font-size: 0.9em;
      margin-bottom: 4px;;
    }

  }