@import "global";
//flex layout styles

.flex-table-container {
    display: flex;
    flex-direction: column;
}

.flex-table-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

@include media-breakpoint-down(sm) {
    .flex-table-entry {
        display: flex;
        flex-direction: column;
    }
    .flex-column-header {
        display: none;
    }
    .first-row {
        border: none !important;
    }
    .flex-cell-data{
        flex: 0 0 70%;
        text-align: end;
    }
    .flex-cell-header{
        flex: 0 0 30%;
    }
}

@include media-breakpoint-up(md) {
    .flex-table-entry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .flex-column-header {
        font-weight: bold;
    }
    .flex-cell-header {
        display: none;
    }
    .flex-item-activity {
        flex: 0 0 45%;
        // padding-right: 20px;
    }
    .flex-item-co2 {
        flex: 0 0 20%;
        // padding-left: 20px;
        // padding-right: 0px;
    }
    .flex-item-source-link {
        flex: 0 0 35%;
        // padding-left: 20px;
    }
}

//styling
.entry-separator {
    border-top: solid 1px white;
}
.source-table {
    padding: 20px;
    color: white;
    line-height: 1.8;
    border-collapse: collapse;
    border: solid 1px white;
}

.list-style {
    list-style-type: none;
    padding: 10px 0;
    margin: 0;
}
.flex-cell-data a {
    color: white;
    text-decoration: underline;
}

.source-table-cell {
     padding: 10px;
}
