@import "global";

.tip-section{
  padding-top: 40px;
  padding-bottom: 40px;
 }

.tip-section-flex-container{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
 }
.tip-section-header-item{
  flex: 1 40%;
}
.tip-section-content-item{
  flex: 1 80%;
}

.tip-section-header-flex-container{
  display: flex;
  flex-direction: column;
}
.tip-section-title-item{
  text-align: center;
  flex: 1 45%;
}
.tip-section-hearts-item{
  flex: 1 55%;
}

.flex-container-hearts{
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}
.heart-range-item{
  flex: 1 33.333%;
}

.heart{
  text-align: center;
  width: auto;
  margin-top: 0.83em;
  margin-right: 0.2em;
  margin-bottom: 1.2em;
  margin-left: 0.2em;
  padding: 0;
}
.range-text{
  font-size: 0.7em;
  color: $dark-purple;
  line-height: 1;
  white-space: nowrap;
  margin-bottom: 5px;
}
.bold-text{
  font-weight: 700;
}

.card{
  background-color: $turquoise;
  color: white;
 }
.card-header{
  border-bottom: 0px;
  cursor: pointer;
  padding: 0.6rem 0.75rem;
}
.card-header h2{
  margin: 10px 15px;
}
.flex-container-tip-card-header{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}
.tip-card-header-icon{
  flex: 0 0 40px;
}
.tip-card-header-text{
  flex-grow: 1;
}
.tip-card-header-opener{
  flex: 0 0 20px;
}
.tip-card-body{
  padding: 0px 50px 20px 65px;
}
.card-body p{
  padding-bottom: 15px;
  line-height: 1.8;
}
.learn-more-button{
  width: 100%;
  border: solid 1px white;
  border-radius: 1px;
  color: white;
  font-weight: bold;
  padding: 15px 20px;
}

@include media-breakpoint-up(md) { //Large devices (more than 992px)
  .tip-section-title-item{
    padding: 0 30px 0 0;
  }
  .heart{
    margin-left: -1.5em;
  }
}

@include media-breakpoint-down(md) { //Medium devices (tablets, less than 992px)
  .tip-section-flex-container{
    flex-direction: column;
    align-items: stretch;
  }
  .tip-section-title-item svg{
    width: 50%;
  }
}
@include media-breakpoint-down(sm) { // Small devices (landscape phones, less than 768px)
  .tip-section-header-flex-container{
    flex-direction: row;
    justify-content: space-between;
   }
}
@include media-breakpoint-down(xs) { // Extra small devices (portrait phones, less than 576px)
  .tip-section-header-flex-container{
    flex-direction: column;
   }
 
  .card-body{
    padding: 10px 10px 20px 10px;
  }
  .card-body p{
    padding: 0px 25px 15px 25px;
    line-height: 1.8;
  }
  .tip-card-header-text{
    font-size: 1.4rem;
  }
}




